<template>
  <v-container fluid>
    <v-card class="mb-3 py-0" v-if="filter">
      <v-card-actions class="py-0 px-5">
        <v-row>
          <v-col cols="12" md="3">
            <v-text-field v-model="search" prepend-icon="fa-search" label="Meklēt" single-line hide-details/>
          </v-col>
          <v-col cols="12" md="2">
            <v-select label="Status" prepend-icon="fa-toggle-on" v-model="status" :items="users" item-text="status" clearable/>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
    <v-card>
      <v-toolbar dense flat>
        <v-toolbar-title>Menedžeris</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon :to="{name: 'userCreate'}" color="primary">
          <v-icon>fa-plus</v-icon>
        </v-btn>
        <v-btn icon color="primary" @click="filter = !filter">
          <v-icon>fa-sliders-h</v-icon>
        </v-btn>
      </v-toolbar>
      <v-data-table
        :headers="headers"
        :items="users"
        item-key="item.id"
        :loading="loading"
        loading-text="Notiek ielāde... Lūdzu, uzgaidiet"
        :search="search">
        <template v-slot:item.actions="{ item }">
          <v-btn icon color="primary" :to="{name: 'userEdit', params: {id: item.id}}">
            <v-icon>fa-eye</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import axios from '@/axios'

export default {
  name: 'UserIndex',
  data: () => ({
    filter: false,
    loading: true,
    search: '',
    status: null,
    users: []
  }),
  computed: {
    headers () {
      return [
        {
          text: 'ID',
          value: 'id'
        },
        {
          text: 'Name',
          value: 'name'
        },
        {
          text: 'Email',
          value: 'email'
        },
        {
          text: 'Status',
          value: 'status',
          filter: value => {
            if (!this.status) return true
            return value === this.status
          }
        },
        {
          text: 'Actions',
          value: 'actions'
        }
      ]
    }
  },
  methods: {
    getUsers () {
      axios.get('users').then(response => {
        this.users = response.data
        this.loading = false
      })
    }
  },
  created () {
    this.getUsers()
  }
}
</script>

<style scoped>

</style>
